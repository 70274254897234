`<template>
  <div id="loginPage">
    <!-- <div class="loadding" v-if="!invalid">登陆中…………</div>
    <div class="invalid" v-if="invalid">登录失效</div> -->
  </div>
</template> 
<script>
import {
  reactive,
  toRefs,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  computed,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { setStorage, getStorage, removeStorage } from "@/js/common";
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { CreatToken, ToLogin, GetTransferData } from "@/js/loginApi.js";
export default defineComponent({
  setup () {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      invalid: false,
      navdata: [],
      navdata02: [
        {
          title: "项目总况",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon01n.png"),
          ia: require("@/assets/images/common/cicon01a.png"),
          children: [
            {
              title: "工程信息",
              path: "/home/index",
            },
            {
              title: "机具统计",
              path: "/home/ImplementStatistical",
            },
            {
              title: "项目大事记",
              path: "/home/projects",
            },
          ],
        },
        {
          title: "生产管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon02n.png"),
          ia: require("@/assets/images/common/cicon02a.png"),
          children: [
            {
              title: "计划进度",
              path: "/home/productionManagement/plan",
            },
            {
              title: "实际进度",
              path: "/home/productionManagement/actual",
            },
          ],
        },
        {
          title: "安全管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon03n.png"),
          ia: require("@/assets/images/common/cicon03a.png"),
          children: [
            {
              title: "安全交底",
              path: "/home/safeManagement/safeDisclose",
            },
            {
              title: "重大危险源",
              path: "/home/safeManagement/majorHazard",
            },
            {
              title: "安全大事记",
              path: "/home/safeManagement/safetyMemorabilia",
            },{
              title: "VR安全教育",
              path: "/home/vrSafetyEducation/index",
            },
          ],
        },
        {
          title: "质量管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon04n.png"),
          ia: require("@/assets/images/common/cicon04a.png"),
          children: [
            {
              title: "质量交底",
              path: "/home/qualityManagement/qualityDisclose",
            },
            {
              title: "质量样板",
              path: "/home/qualityManagement/qualitymajorHazard",
            },
            {
              title: "质量大事记",
              path: "/home/qualityManagement/qualityMemorabilia",
            },{
              title: "工艺模拟",
              path: "/home/processSmulation/processSimulationList",
            },
          ],
        }, {
          title: "物料管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cion12n.png"),
          ia: require("@/assets/images/common/cion12a.png"),
          children: [
           {
              title: "材料入库",
              path: "/home/materialManagement/materialWarehousing",
            },{
              title: "材料出库",
              path: "/home/materialManagement/materialIssue",
            },{
              title: "材料领料",
              path: "/home/materialManagement/materialPicking",
            },{
              title: "材料退场",
              path: "/home/materialManagement/demobilizationMaterials",
            },
          ],
        },
        {
          title: "人员管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon05n.png"),
          ia: require("@/assets/images/common/cicon05a.png"),
          children: [
            {
              title: "优秀员工",
              path: "/home/outstanding/outstanding",
            },
          ],
        },
        {
          title: "绿色施工",
          path: "",
          down: false,
          in: require("@/assets/images/common/cion11n.png"),
          ia: require("@/assets/images/common/cion11a.png"),
          children: [
            {
              title: "建筑垃圾管理",
              path: "/home/constructionWaste/index",
            },{
              title: "垃圾分类",
              path: "/home/refuseClassification/index",
            },
          ],
        },
        {
          title: "决策分析",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon06n.png"),
          ia: require("@/assets/images/common/cicon06a.png"),
          children: [
            {
              title: "产值完成额",
              path: "/home/decision/qualityMemorabilia",
            },
            {
              title: "资金回收额",
              path: "/home/decision/outputValue",
            },
          ],
        },

        {
          title: "模型管理",
          path: "/home/modelManagement",
          down: false,
          in: require("@/assets/images/common/cicon08n.png"),
          ia: require("@/assets/images/common/cicon08a.png"),
          children: [],
        },{
          title: "资料管理",
          path: "/home/fileManagement",
          down: false,
          in: require("@/assets/images/common/cicon08n.png"),
          ia: require("@/assets/images/common/cicon08a.png"),
          children: [],
        },{
          title: "设置管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cion10n.png"),
          ia: require("@/assets/images/common/cion10a.png"),
          children: [
            {
              title:"材料设置",
              path:"/home/settingManagement/materialSettings"
            },{
              title:"堆场位置",
              path:"/home/settingManagement/locationStorageYard"
            },{
              title:"水电超标",
              path:"/home/settingManagement/waterAndElectricity"
            },{
              title:"告警音频",
              path:"/home/settingManagement/givealarmAudio"
            },{
              title:"任务预警",
              path:"/home/settingManagement/taskAlert"
            },{
              title:"材料编码规则",
              path:"/home/settingManagement/materialCodingRules"
            },{
              title:"材料库存预警",
              path:"/home/settingManagement/materialInventoryAlert"
            }
          ],
        },
        {
          title: "施工单位管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cion13n.png"),
          ia: require("@/assets/images/common/cion13a.png"),
          children: [
            {
              title: "施工单位列表",
              path: "/home/Construction/list",
            },
          ],
        },
        {
          title: "系统管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon07n.png"),
          ia: require("@/assets/images/common/cicon07a.png"),
          children: [
            {
              title: "权限设置",
              path: "/home/systemManagement/jurisdiction",
            },
            {
              title: "用户设置",
              path: "/home/systemManagement/user",
            },
          ],
        },
      ],
    });
    const setToken = () => {
      console.log(route.query,255);
      let token = route.query.token;
      let pid = route.query.pid;
      let uid = route.query.uid;
      // 测试
      //  let token="74ad83cf-799f-4d4d-9077-42524bb08565"
      //  let pid= "e3f42e96-7c0a-44d2-919a-4ed4d1856d91"
      //   let uid= "e3f42e96-7c0a-44d2-919a-4ed4d1856d91"
      if (token && pid && uid) {
        setStorage("token", token);
        setStorage("bscdata", { pid: pid, uid: uid });
        ElMessage({
          message: "欢迎使用智慧工地台管理系统",
          type: "success",
          showClose: true,
        });
        // methods.setnav(
        //    { text: "工程信息", path: "/home/index" },
        //   { text: "项目总况", path: "" },

        // );
        methods.GetTransferData(token);
      } else {
        state.invalid = true;
      }
    };
    const methods = {
      GetTransferData: (token) => {
        let data = {
          token: token,
        };
        GetTransferData(data).then((res) => {
          if (res.data.Code == 1 && res.data.Data) {
            let IsProjectUser = res.data.Data.IsProjectUser;
            let Rolist = res.data.Data.Rolist[0].roolList;
            // IsProjectUser: 1 显示所有，其他Rolist判断权限
            // if (IsProjectUser == 1) {
            //   state.navdata = state.navdata02;
            //   state.navdata[0].down = true;
            //   setStorage("navdata", state.navdata);

            //   methods.setnav(
            //     { text: "工程信息", path: "/home/index" },
            //     { text: "项目总况", path: "" }
            //   );
            //   router.replace("/home/index");
            // } else {
              state.navdata02.forEach((items, indexs) => {
                Rolist.forEach((item, index) => {
                  if (items.title == item.MenuName) {
                    state.navdata.push(items);
                  }
                });
              });
              
              console.log(state.navdata, 236)
      
              state.navdata[0].down = true;
              setStorage("navdata", state.navdata);
            console.log(state.navdata, 3285888)
              methods.setnav(
                {
                  text: state.navdata[0].children[0].title,
                  path: state.navdata[0].children[0].path,
                },
                { text: state.navdata[0].title, path: "" }
              );
              router.replace(state.navdata[0].children[0].path)
            // }
          }
        });
      },

      setnav: (a, b) => {
        let cachedata = getStorage("cachedata") || {};
        cachedata.SetactiveNavChild = a;
        cachedata.SetactiveNavParent = b;

        setStorage("cachedata", cachedata);

      },

      sibmitLogin: () => {
        if (!state.username && !state.password) {
          return;
        }
        let datas = {
          name: state.username,
          pwd: state.password,
          loginType: 4,
        };
        CreatToken(datas).then((res) => {
          if (res.data.code == 1) {
            setStorage("token", res.data.data);
            methods.ToLogin(res.data.data);
            ElMessage({
              message: "欢迎使用云励积分制工作管理平台",
              type: "success",
              showClose: true,
            });

            methods.setnav(
              { text: "公司注册列表", path: "/home/companyList" },
              { text: "公司管理", path: "" }
            );

            router.push("/home/companyList");
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });
      },
      ToLogin: (datas) => {
        let tokens = {
          token: datas,
        };
        ToLogin(tokens).then((res) => {
          if (res.data.code == 1) {
            setStorage("usernames", res.data.data);
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });
      },
    };
    onMounted(() => {
      console.log(387777)
      setToken();
    });

    return {
      ...toRefs(state),
      ...methods,
    };
  },
});
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#loginPage {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>